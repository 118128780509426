<template>
  <v-container>
    <h1 class="mb-4">
      {{ trl("app_login_welcome", $store.state.me.firstname) }}
    </h1>
    <v-banner
      v-if="$store.state.me.tenants.length === 0"
      rounded
      color="error white--text"
      icon="mdi-alert-octagon"
    >
      <h3>{{ trl("user_no_role_assigned") }}</h3>
      <p class="mt-1">{{ trl("user_no_role_assigned_action", $store.state.me.email) }}</p>
    </v-banner>
    <div v-else>
    <v-layout v-if="$store.state.me.tenants.length > 1" row class="my-4">
      
      <v-col xs12 sm6 md4 lg3 xl3 v-for="t in $store.state.me.tenants" :key="t.id">
        <v-card
          outlined
          style="display: flex"
          :to="{params: {tenant: t.path}, name: 'TenantHome'}"
          v-model="current.id"
          class="tenant"
          :class="{ current: t.id === current.id }"
        >
          <img v-if="t.logo" class="logo" :src="'/uploads/tenants/' + t.logo" />
          <div v-else class="logo">{{ t.name }}</div>
        </v-card>
      </v-col>
    </v-layout>

    <v-list v-if="current.id">
      <v-list-item-group color="primary">
        <v-list-item
          v-for="(item, index) in perms.filter(p => !p.dev)"
          :key="index"
          :to="`/${current.path}/${item.path}`"
          :class="{'mb-4': item.sep, 'dev': item.dev}"
        >
          <v-list-item-icon
            ><v-icon v-text="item.icon" color="primary"></v-icon
          ></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
              trl(item.label)
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-banner v-else>
      Dein Konto erlaubt den Zugriff auf mehrere Mandanten. Wähle einen aus.
    </v-banner>
    </div>
    <v-footer fixed>
      <v-row class="py-8" justify="center">
        <small>
          <span class="mr-6">&copy; 2021 - 2024 Loft 66</span>
          <span
            >Made with
            <v-tooltip top open-delay="500">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" style="cursor: default"
                  >♥️🩸</span
                >
              </template>
              Herzblut natürlich! 😁
            </v-tooltip>
            in Düsseldorf</span
          >
          <span class="ml-6">Version {{ app.version }} (Build {{ app.build }})</span>
        </small>
      </v-row>
    </v-footer>
  </v-container>
</template>

<script>
import { numerus, trl } from "@/utils/strings";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dev: false,
      navItems: [
        { 
          prop: "FC_DIVISION_READ", 
          label: "FC_DIVISION_READ_menu",
          icon: "mdi-table-edit", 
          path: "budget" 
        },
         {
          prop: "COSTS_READ",
          label: "Kreditoren und Fremdkosten",
          icon: "mdi-account-cash",
          path: "costs/live",
          sep: true
        },
        {
          prop: "FC_TENANT_READ",
          label: "Forecast Gesamtentwicklung",
          icon: "mdi-chart-timeline-variant-shimmer",
          path: "progress",
        },
         {
          prop: "FC_TENANT_READ",
          label: "Forecast Vergleich",
          icon: "mdi-ab-testing",
          path: "compare"
        },
        {
          prop: "FC_TENANT_READ",
          label: "FC_TENANT_READ_menu",
          icon: "mdi-database-search",
          path: "explore",
        },
        {
          prop: "FC_TENANT_WRITE",
          label: "FC_TENANT_WRITE_menu",
          icon: "mdi-calendar-multiple",
          path: "forecasts",
          sep: true
        },
        { 
          prop: "CLIENTS_READ", 
          label: "CLIENTS_READ_menu", 
          icon: "mdi-domain", 
          path: "clients"
        },
        { 
          prop: "USERS_READ", 
          label: "USERS_READ_menu", 
          icon: "mdi-account-multiple", 
          path: "users" 
        },
        {
          prop: "DIVISIONS_READ",
          label: "DIVISIONS_READ_menu",
          icon: "mdi-select-group",
          path: "divisions",
        },
        {
          prop: "ATTRIBUTES_MANAGE",
          label: "ATTRIBUTES_MANAGE_menu",
          icon: "mdi-format-list-checks",
          path: "attributes"
         
        }
      ],
    };
  },
  computed: {
    ...mapGetters(["current"]),
    app() {
      return {
        title: process.env.VUE_APP_TITLE,
        version: process.env.VUE_APP_VERSION,
        build: process.env.VUE_APP_BUILD,
      };
    },
    perms() {
      return this.navItems.filter((i) => this.current.permissions[i.prop]);
    }
  },
  methods: {
    selectTenant(id) {
      this.$store.commit("setTenant", id);
    },
    numerus,
    trl,
  },
  beforeRouteUpdate(from, to, next) {
      if (from.params.tenant){
        this.selectTenant(this.$store.state.me.tenants.find(t => t.path === from.params.tenant).id);
        next();
      }

  },
  created(){
    let tenants = this.$store.state.me.tenants;
    if (tenants.length === 1){
      this.$router.push({ name: 'TenantHome', params: { tenant: tenants[0].path }, replace: true }).catch(()=>{});
      //console.log(this.$route.params.tenant);
    }
  }
  
};
</script>

<style lang="scss" scoped>
.tenant {
  height: 150px;

  .logo{
    max-width: 128px;
    max-height: 64px;
    margin: auto;
    background-color: #fff;
    font-weight: bold;
  }

  &.current {
    background-color: #fff;
    border: 1px solid var(--v-anchor-base) !important;
  }
}

</style>